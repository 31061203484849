<template>
    <div class="full-width d-flex justify-center ticket-send-modal">
        <v-btn class="receive-ticket-button" color="primary" :disabled="!enabled" @click="showReceiveTicketModal = true" rounded> {{$t(btnLabelKey)}} </v-btn>

        <v-dialog v-model="showReceiveTicketModal" content-class="ticket-modal ">
            <v-card>
                <v-card-title>
                    <v-tabs active-class="custom-tabs">
                        <v-tab @click="tabToShow = 'email'">{{$t('receiptModalMailLabel')}}</v-tab>
                        <v-tab @click="tabToShow = 'sms'">{{$t('receiptModalSMSLabel')}}</v-tab>
                    </v-tabs>
                </v-card-title>
        
                <v-card-text v-if="tabToShow == 'email'">
                    <span class="mb-2 d-flex primary--text text-center">{{$t("receiptModalMailText")}}</span>
                    <v-text-field outlined rounded hide-details v-model="email" placeholder="jean.dupon@email.com" class="custom-textfield"></v-text-field>
                </v-card-text>
        
                <v-card-text v-else>
                    <span class="d-flex primary--text text-center">{{$t("receiptModalSMSText")}}</span>
                    <vue-phone-number-input class="my-2" :default-country-code="isNotNull(tel) && isNotNull(tel.countryCode) ? tel.countryCode : 'FR'" :preferred-countries="['FR', 'BE', 'DE', 'ES','IT', 'PT', 'US', 'GB', 'CH']" v-model="phoneNumber" @update="tel = $event" :no-example="false"/>
                </v-card-text>
        
                <v-divider class="primary mx-8"></v-divider>
        
                <v-card-actions class="justify-center">
                    <v-btn outlined small color="primary" class="mb-2 me-2 receive-ticket-modal-action" @click="showReceiveTicketModal = false">{{$t('close')}}</v-btn>
                    <v-btn small color="primary" class="mb-2 receive-ticket-modal-action" @click="sendTicket">{{$t('send')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import {sendMail, sendSMS} from "@/services/SenderService"

export default {
    props: {
        enabled: {
            type: Boolean,
            default: true
        },
        btnLabelKey:{
            type: String,
            default: "receiveReceipt"
        }
    },
    data(){
        return{
            tabToShow: "email",
            email:"",
            tel: null,
            phoneNumber: "",
            showReceiveTicketModal: false
        }
    },
    created(){
        this.email = this.customerEmail
    },
    methods:{
        async sendTicket(){
            const ticketUrl = window.location.origin + "/ticketbank?tId=" + this.lastTicket.complexId;
            let response = null
            try{
                if(this.tabToShow == "email" && this.isNotNull(this.email)){
                    response = await sendMail(this.email, ticketUrl, this.lastTicket.complexId, this.lastTicket.orderNumber, this.$i18n.locale);
                }else if(this.tabToShow == "sms" && this.tel.isValid){
                    response = await sendSMS(this.$t("ticketSms") + ticketUrl, this.tel.formattedNumber.replace("+", "00"))
                }

                if(response && response.data && !response.data.error){
                this.showSnackbar({
                        message: this.$t('receiptModalSent'),
                        type: "success",
                        timeout: 10
                    })
                }else{
                    this.showSnackbar({
                        message: this.$t('receiptModalSendError'),
                        type: "error",
                        timeout: 10
                    })
                }
            }catch(e){
                this.showSnackbar({
                    message: this.$t('receiptModalSendError'),
                    type: "error",
                    timeout: 10
                })
            }
            
            this.showReceiveTicketModal = false
        }
    },
    computed:{
        isBtnActive(){
            return this.lastTicket != null
        }
    }
}
</script>
<style scoped>
    
.custom-textfield fieldset{
    border-color: var(--color-primary) !important;
}

.custom-textfield >>> .v-input__slot{
    min-height: 50px !important;
}

.custom-textfield label{
    top: 14px !important;
}
>>> .v-dialog{
    overflow-y: visible !important;
}
</style>