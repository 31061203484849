const axios = require('axios');
import { config } from '@/assets/js/Utils.js';

const smsSendURL = "https://dkw-demokit.pubqlf.co.as8677.net/demonstration/sendSMS"

const senderConfig = {
    mail: {
        subjectMail: "Thank you for your purchase. This is your e-receipt",
        senderMail: "no-reply-weshop@worldline.com",
        senderName: "WorldlineShop",
        template: "iec_augmented_seller",
        mailsAdmins: "wldd.atosworldline@gmail.com",
    }
}


export function sendMail(recipient, link, basketComplexId, orderNumber, lang){
    var path = "/mail/send/ticket";
    
    const params = new URLSearchParams();
    params.append('mailsAdmins', senderConfig.mail.mailsAdmins);
    params.append('subject', encodeURIComponent(senderConfig.mail.subjectMail) + " for your order #" + orderNumber);
    params.append('template', senderConfig.mail.template);
    params.append('senderMail', senderConfig.mail.senderMail);
    params.append('senderName', senderConfig.mail.senderName);
    params.append('link', link);
    params.append('lang', lang);
    params.append('recipient', recipient);
    params.append('basketComplexId', basketComplexId);

    return axios.post(config.WS_URL + path, params)
  }

  export function sendSMS(message, recipient){
    const params = new URLSearchParams();
    params.append('phoneNumber', recipient);
    params.append('message', message.replace(/ /g,"+").replace(/&/g,"%26"));

    return axios.post(smsSendURL, params)
  }
